import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { makeStyles } from 'tss-react/mui';
import Warning from "@mui/icons-material/Warning";
import { useAuthContext } from "../../contexts/auth";
import Button from "../CustomButtons/Button";
import SnackbarContent from "../Snackbar/SnackbarContent";

import {
  UserLoginCredentials,
  useUserLoginRequest,
} from "../../request/api";

import styles from "../../styles/jss/nextjs-material-kit/pages/forumsStyle.js";
import FormControl from "@mui/material/FormControl";
import {TextField} from "@mui/material";
import Grid from "@mui/material/Grid";
import Link from "next/link";
import {useRouter} from "next/router";
import { updateQueryString } from "../../util/url";

const useStyles = makeStyles()(styles);

export default function LoginForm({ closeFromInside, onSuccess }) {
  /**
   * @type {UserLoginCredentials}
   */
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
  });

  const { login } = useAuthContext();
  const [loginError, setLoginError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { classes } = useStyles();
  const { isMutating, trigger: triggerLogin } = useUserLoginRequest(undefined, { withCredentials: true });

  const router = useRouter();

  const handleInputChange = (event) => {
    setCredentials({
      ...credentials,
      [event.target.name.replace('login_', '')]: event.target.value,
    });
  }

  const handleLogin = (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    // Very much a hack but there are session issues doing it in via the authentication listener
    const formData = new FormData();
    formData.append('LoginForm[_username]', credentials.email);
    formData.append('LoginForm[_password]', credentials.password);

    window.gtag && window.gtag('event', 'login');

    fetch(process.env.NEXT_PUBLIC_API_URI + '/../login_check', {
      method: 'POST',
      body:  formData,
      redirect: 'manual',
    })
    .catch(error => {
      console.log(error);
    });

    triggerLogin({
      body: credentials,
    }).then(async(response) => {
      const user = await login();

      onSuccess(user);
      closeFromInside();
    }).catch((error) => {
      setLoginError(error.response?.data?.error || error.response?.data?.message || error.message);
    }).finally(() => {
      setIsSubmitting(false);
    });
  }

  return (
    <div className={classes.loginModalFormContainer}>
      <form className={classes.form} onSubmit={handleLogin}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <label htmlFor="email" className={classes.loginLabel}>
                Email
              </label>
              <TextField
                  required
                  id="email"
                  name='email'
                  type="email"
                  value={credentials.email}
                  onChange={handleInputChange}
                  variant="outlined"
                  className={classes.loginInput}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <label htmlFor="email" className={classes.loginLabel}>
                Password
              </label>
              <TextField
                  required
                  id="password"
                  name='password'
                  type="password"
                  value={credentials.password}
                  onChange={handleInputChange}
                  variant="outlined"
                  className={classes.loginInput}
                  sx={{
                    '& .MuiInputBase-input': {
                      color: "blue"
                    },
                    '& .MuiOutlinedInput-input': {
                      color: "blue"
                    },
                    '&:-webkit-autofill': {
                        WebkitBoxShadow: '0 0 0 100px #307ECC inset !important',
                        backgroundColor: '#307ECC !important',
                        WebkitTextFillColor: 'ffffff',
                        backgroundClip: "text",
                    },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Link href={{ pathname: router.asPath.split('?')[0], query: { password: '' }}}>
              Forgot password or resend verification email?
            </Link>
          </Grid>
          {loginError ? <Grid item xs={12}>
            <SnackbarContent
              message={loginError}
              color="warning"
              icon={Warning}
            /> 
          </Grid> : null}
          <Grid item xs={12}>
            <Button
              color="primary"
              type={'submit'}
              disabled={isSubmitting}
              fullWidth
              className={classes.buttonOverride}
            >Login</Button>
          </Grid>
          <Grid item xs={12}>
          <p className={classes.loginModalFooter}>Don't have an account? <Link href={updateQueryString(router.asPath, "register", null)} shallow={true}>Register here</Link></p>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

LoginForm.propTypes = {
  closeFromInside: PropTypes.func.isRequired,
};
